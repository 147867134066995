// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, H4, Em } from '@tackl';

import bgPattern from '@images/loader-pattern.jpg';
import hPattern from '@images/horizontal-pattern.png';
import vPattern from '@images/vertical-pattern.png';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 112;

    background: ${props.theme.colors.brand.bc2};
    background-image: url(${bgPattern});
    background-repeat: repeat;
    background-size: 5.7rem 7.4rem;
    pointer-events: none;
    user-select: none;

    clip-path: polygon(${props.isDone ? `0% -25%, 100% 0%, 100% 0%, 0% 0%` : `0% 0%, 100% 0%, 100% 100%, 0% 100%`});

    transition: clip-path 1.2s ${props.theme.easing.bezzy};
`);

const sharedContainerStyles = css`
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: calc(100vw - 4.8rem);
    height: 100%;
    max-height: 22rem;

    ${breakup.medium`
        max-width: calc(8.333vw * 6);
    `}    

    ${breakup.large`
        width: 33.333vw;
        max-width: 100%;

        left: 50%;
        transform: translate(-50%, -50%);
    `}
`;

export const Pattern = styled.div(props => css`
    ${sharedContainerStyles}
    z-index: 1;
`);


const girth = `1.5rem`;

const sharedPatternStyles = props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;

    span {
        display: block;
        position: absolute;
        overflow: hidden;


        &:nth-child(1),
        &:nth-child(3) {
            background-image: url(${hPattern});
            background-size: 1.5rem 1.2rem;
            width: 100%;
            height: ${girth};
        }

        &:nth-child(2),
        &:nth-child(4) {
            background-image: url(${vPattern});
            background-size: 1.2rem 1.5rem;
            width: ${girth};
        }

        &:nth-child(1) {
            top: 0;
            left: 0;
            right: 0;
        }

        &:nth-child(2) {
            top: 1.5rem;
            right: 0;
            bottom: 1.5rem;
        }

        &:nth-child(3) {
            bottom: 0;
            left: 0;
            right: 0;
        }

        &:nth-child(4) {
            top: 1.5rem;
            left: 0;
            bottom: 1.5rem;
        }
    }
`;


export const PatternBG = styled.div(props => css`
    ${sharedPatternStyles}
    z-index: 1;
    opacity: 0.25;
`);

export const AnimatedPattern = styled.div(props => css`
    ${sharedPatternStyles}
    z-index: 2;

    span {

        &:nth-child(1) {
            clip-path: inset(${props.play ? `0% 0% 0% 0%` : `0% 100% 0% 0%`});
            transition: clip-path 1s ease-in-out;
        }

        /* Right */
        &:nth-child(2) {
            clip-path: inset(${props.play ? `0% 0% 0% 0%` : `0% 0% 100% 0%`});
            transition: clip-path 0.75s ease-in-out;
            transition-delay: 1s;
        }

        /* Bottom */
        &:nth-child(3) {
            clip-path: inset(${props.play ? `0% 0% 0% 0%` : `0% 0% 0% 100%`});
            transition: clip-path 0.75s ease-in-out;
            transition-delay: 1.75s;
        }

        /* Left */
        &:nth-child(4) {
            clip-path: inset(${props.play ? `0% 0% 0% 0%` : `100% 0% 0% 0%`});
            transition: clip-path 1s ease-in-out;
            transition-delay: 2.5s;
        }
    }
`);

export const Content = styled.div(props => css`
    ${sharedContainerStyles}
    z-index: 2;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    padding: 0 7.2rem;
    text-align: center;
    
    @media (max-width: 1275px) {
        max-width: 300px;
      }
`);


const sharedTextStyles = props => css`
    color: ${props.theme.colors.brand.bc1};
`;


export const Text = styled(H4)(props => css`
    ${sharedTextStyles}
`);

export const Loading = styled(Em)(props => css`
    ${sharedTextStyles}
`);