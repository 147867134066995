// Imports
// ------
import React from 'react';
import { useWindowSize } from 'react-use';

// Component
// ------
export const useHeightFix = () => {
    // NOTE • Window Size Checker
    const { width } = useWindowSize();

    // NOTE • OnLoad: 100vh Fix
    React.useEffect(() => {
        const setHeightVar = () => {
            let vh = document.documentElement.clientHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        // Init
        setHeightVar();

        const resizeChecker = () => {
            if (width > 768) {
                setHeightVar();
            }
        }

        // Initialize resize
        window.addEventListener('resize', resizeChecker);

        return () => {
            window.removeEventListener('resize', resizeChecker);
        };
    }, []);

    return null;
}