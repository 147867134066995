// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const Button = ({ to, children, isDisabled }) => {
	return (
		<Jacket
			href={to}
			target='_blank'
			rel='noopener noreferrer'
			className='hover-trigger'
			isDisabled={isDisabled}
			aria-label={`Go to ${children}`}>
			{children}
		</Jacket>
	);
};

export default Button;
