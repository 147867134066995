// Imports
// ------
import React from 'react';
import { useHeightFix } from '@utils/useHeightFix';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '@theme';
import Loader from '@parts/Loader';
import SmoothScroll from '@parts/SmoothScroll';
import Header from '@parts/Header';
import Cursor from '@parts/Cursor';
import GlobalHeadingAnimation from '@parts/GlobalHeadingAnimation';
import Grid from '@parts/Grid';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import loadable from '@loadable/component';

// Styles
// ------
import '@css/global.css';

// Component
// ------
const VideoBackground = loadable(() => import('@parts/VideoBackground'));

const Layout = ({ children, location }) => {
	// NOTE • Mobile Height fixer
	useHeightFix();

	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • 404 Path
	const is404 = location.pathname !== '/404/';

	return (
		<>
			<ThemeProvider theme={theme}>
				{is404 && (
					<>
						<Loader />
						<Header />
					</>
				)}

				{bp.large && <Cursor location={location} />}

				{is404 && <VideoBackground />}

				<GlobalHeadingAnimation />

				<SmoothScroll>{children}</SmoothScroll>
			</ThemeProvider>

			<GlobalStyle theme={theme} />
		</>
	);
};

export default Layout;
