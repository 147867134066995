// Imports
// ------
import styled, { css } from 'styled-components';
import { Main, breakup } from '@tackl';

// Exports
// ------
export const Fill = styled.span`
	width: 100%;
	height: 100%;
	transition: all 0.25s linear;
`;

export const Jacket = styled(Main)(
	(props) => css`
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		height: ${props.showGrid ? `100%` : `0%`};
		pointer-events: none;
		transition: all 1s ${props.theme.bezzy};
		padding: 0 1.2rem;

		${breakup.large`
			padding: 0;
		`}

		${Fill} {
			opacity: ${props.altColor ? 0.5 : 0.2};
			background-color: ${props.altColor ? props.theme.colors.brand.bc1 : props.theme.colors.brand.bc3};
		}

		> div {
			height: 100%;
		}

		.row {
			div {
				height: 100%;
			}
		}
	`
);
