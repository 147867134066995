// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { emStyles } from '@tackl/type';
import { Link } from 'gatsby';

// Exports
// ------------

const sharedStyles = (props) => css`
	${emStyles}
	padding: 0.4rem 1.2rem 0.2rem;
	width: fit-content;
	border-radius: 2.1rem;
	border: 1px solid ${props.theme.colors.brand.bc1};
	background: transparent;
	color: ${props.theme.colors.brand.bc1};
	transition: ${props.theme.easing.ease};

	&:visited {
		color: ${props.theme.colors.brand.bc1};
	}

	&:hover {
		${breakup.large`
            background: ${props.theme.colors.brand.bc1};
            color: ${props.theme.colors.brand.bc2};
        `}
	}

	&:active {
		background: ${props.theme.colors.brand.bc1d};
	}

	${props.isDisabled &&
	css`
		pointer-events: none;
		user-select: none;
		opacity: 0.5;
	`}
`;

export const Jacket = styled.a(
	(props) => css`
		${sharedStyles}
	`
);

export const JacketInt = styled(Link)(
	(props) => css`
		${sharedStyles}
	`
);
