// Imports
// ------------
import React, { useLayoutEffect } from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/all';


// Component
// ------------
const GlobalHeadingAnimation = () => {
    // NOTE • Refs
    const main = React.useRef();

    // NOTE • Split variable
    let split;

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {

            let mm = gsap.matchMedia();
            let breakPoint = 1024;


            mm.add("(min-width: 1024px)", () => {
                const titles = document.querySelectorAll('.text-animate');

                titles.forEach(title => {
                    split = new SplitText(title, {
                        type: "words,chars",
                        wordsClass: "word",
                        charsClass: "char"
                    });

                    const words = title.querySelectorAll('.word');

                    words.forEach(word => {

                        gsap.fromTo(word, {
                            'will-change': 'transform, filter',
                            transformOrigin: '50% 100%',
                            filter: 'blur(12px) opacity(0)'
                        },
                            {
                                ease: 'power2.inOut',
                                y: 0,
                                rotation: 0,
                                scale: 1,
                                filter: 'blur(0px) opacity(1)',
                                scrollTrigger: {
                                    trigger: word,
                                    start: 'top bottom+=40%',
                                    end: 'top top+=50%',
                                    scrub: true,
                                    markers: false
                                },
                                stagger: {
                                    amount: 0.15,
                                    from: 'center'
                                }
                            });
                    });
                });
            });
        }, main);

        return () => {
            ctx.revert();
        }
    }, []);

    return (
        <div ref={main} />
    )
}

export default GlobalHeadingAnimation;