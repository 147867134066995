// Imports
// ------
import React, { useLayoutEffect, useRef, useState } from 'react';
import gsap from 'gsap';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Cursor = ({ location }) => {
	// NOTE • States
	const [isHovered, setIsHovered] = useState(false);
	// NOTE • Refs
	const main = useRef();


	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			gsap.set(main.current, { xPercent: -50, yPercent: -50 });

			let xTo = gsap.quickTo(main.current, "x", { duration: 0.05, ease: "power3" }),
				yTo = gsap.quickTo(main.current, "y", { duration: 0.05, ease: "power3" });

			const move = (e) => {
				xTo(e.x);
				yTo(e.y);
			}

			document.addEventListener("mousemove", move);

			return () => {
				document.removeEventListener("mousemove", move);
			}
		}, main);

		return () => ctx.revert();
	}, []);


	const activateHovers = () => {
		const buttons = document.querySelectorAll('.hover-trigger');

		buttons.forEach((button) => {
			button.addEventListener('mouseover', () => {
				setIsHovered(true);
			});

			button.addEventListener('mouseleave', () => {
				setIsHovered(false);
			});
		});
	}

	useLayoutEffect(() => {
		activateHovers();
	}, [location]);

	return <Jacket ref={main} isHovered={isHovered} />;
}

export default Cursor;