// Imports
// -------
import { css, createGlobalStyle } from 'styled-components';
import Color from 'color';

// *** Theme Styling *** //
// ------
// 1. NOTE • Colours [Global, Social, Feedback, Brand]
// ------
// 2. NOTE • Spacing [Padding & Margin]
// ------
// 3. NOTE • Fonts [Type, Weight]
// ------
// 4. NOTE Grid [Breakpoints, Gutter, Max Grid Size]
// ------
// 5. NOTE • Easings [Bezzy, Global Ease]
// ------
// 6. NOTE • Magic Verticals
// ------
// 7. NOTE • No ScrollBars
// ------
// 8. NOTE • Alignments
// ------

// Exports
// ------
const brandColors = {
	primary: '#132E9D', // Blue
	secondary: '#F0EEE9', // Off-White
	tertiary: '#352E2F' // Brown
}

export const theme = {
	// 1. ANCHOR Colours
	// ------
	colors: {
		global: {
			white: '#ffffff',
			white10: Color('#ffffff').rgb().alpha(0.1),
			white20: Color('#ffffff').rgb().alpha(0.2),
			white30: Color('#ffffff').rgb().alpha(0.3),
			white40: Color('#ffffff').rgb().alpha(0.4),
			white50: Color('#ffffff').rgb().alpha(0.5),
			white60: Color('#ffffff').rgb().alpha(0.6),
			white70: Color('#ffffff').rgb().alpha(0.7),
			white80: Color('#ffffff').rgb().alpha(0.8),
			white90: Color('#ffffff').rgb().alpha(0.9),
			black: '#000000',
			black10: Color('#000000').rgb().alpha(0.1),
			black20: Color('#000000').rgb().alpha(0.2),
			black30: Color('#000000').rgb().alpha(0.3),
			black40: Color('#000000').rgb().alpha(0.4),
			black50: Color('#000000').rgb().alpha(0.5),
			black60: Color('#000000').rgb().alpha(0.6),
			black70: Color('#000000').rgb().alpha(0.7),
			black80: Color('#000000').rgb().alpha(0.8),
			black90: Color('#000000').rgb().alpha(0.9),
		},

		social: {
			facebook: '#3b5998',
			twitter: '#1da1f2',
			creativeMarket: '#8ba753',
			slack: '#e9a820',
			skype: '#00aff0',
			instagram: '#833ab4',
			dribbble: '#ea4c89',
			behance: '#1769ff',
			linkedin: '#0077b5',
			tiktok: '#FE2C55'
		},

		feedback: {
			positive: '#3adb76',
			negative: '#B44110',
			warning: '#ffae00',
		},

		brand: {
			bc1: brandColors.primary,
			bc1d: '#0050C9',
			bc180: Color(brandColors.primary).rgb().alpha(0.8), // 80%
			bc150: Color(brandColors.primary).rgb().alpha(0.5), // 50%
			bc125: Color(brandColors.primary).rgb().alpha(0.25), // 25%
			bc110: Color(brandColors.primary).rgb().alpha(0.1), // 10%

			bc2: brandColors.secondary,
			bc280: Color(brandColors.secondary).rgb().alpha(0.8), // 80%
			bc250: Color(brandColors.secondary).rgb().alpha(0.5), // 50%
			bc225: Color(brandColors.secondary).rgb().alpha(0.25), // 25%
			bc210: Color(brandColors.secondary).rgb().alpha(0.1), // 10%

			bc3: brandColors.tertiary,
			bc380: Color(brandColors.tertiary).rgb().alpha(0.6), // 60%
			bc350: Color(brandColors.tertiary).rgb().alpha(0.4), // 40%
			bc325: Color(brandColors.tertiary).rgb().alpha(0.25), // 25%
			bc310: Color(brandColors.tertiary).rgb().alpha(0.1), // 10%
		}
	},

	// 2. ANCHOR • Padding & Margin
	// ------
	space: {
		mpad: '1.2rem', // Mobile Padding
		small: '7.2rem', // Small
		medium: '9.6rem', // Medium
		large: '12rem', // Large
	},

	// 3. ANCHOR • Fonts
	// ------
	font: {
		type: {
			heading: `'ITC Garamond', serif`,
			headingi: `'ITC Garamond', serif`,
			body: `'FK Grotesk', sans-serif`
		},
		weight: {
			light: 300,
			regular: 400,
			medium: 500,
			semi: 600,
			bold: 700,
		}
	},

	// 4. ANCHOR • Breakpoints
	// ------
	grid: {
		breakpoints: {
			small: '0px',
			smedium: '380px',
			medium: '700px',
			large: '1024px',
			xlarge: '1200px',
			xxlarge: '1400px',
			huge: '1600px',
			uber: '1800px',
		},

		gutter: {
			small: '2.4rem',
			large: '3.6rem',
		},

		maxSize: '1440px'
	},

	// 5. ANCHOR • Easings
	// ------
	easing: {
		bezzy: 'cubic-bezier(0.8, 0, 0, 1)',
		ease: 'all .3s ease-in-out',
	},

	// 6. ANCHOR • Magic Verticals
	// ------
	vh: css`
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	`,

	vhs: css`
		height: 100vh;
		height: calc(var(--vhs, 1vh) * 100);
	`,

	// 7. ANCHOR • No ScrollBars
	// ------
	noscrollbars: css`
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE 10+ */

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent; /* Chrome/Safari/Webkit */
		}
	`,

	// 8. ANCHOR • Alignments
	// ------
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	`,

	valignReset: css`
		position: relative;
		top: auto;
		transform: none;
	`,

	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	talignReset: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,

	borderFix: css`
		-webkit-mask-image: -webkit-radial-gradient(white, black);
	`
};

export const GlobalStyle = createGlobalStyle`
    body {  background: ${theme.colors.brand.bc2} }
    * { color: ${theme.colors.global.bc1} }
`;
