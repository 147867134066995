// Imports
// ------
import { observable } from 'mobx';

// Exports
// ------
export const videoState = observable({
	isReady: false,
});

export const header = observable({
	hide: false
});

export const siteReady = observable({
	isReady: false,
});

export const loadingState = observable({
	isFinished: false,
});