// Imports
// ------
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { SplitText } from 'gsap/SplitText';

// Plugin Register
// ------
if (typeof window !== 'undefined') {
	gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);
}

// Component
// ------
const SmoothScroll = ({ children }) => {
	// NOTE • Refs
	const main = useRef();
	const smoother = useRef();

	// NOTE Before render, calculate smooth scrolling
	useEffect(() => {
		const ctx = gsap.context(() => {
			smoother.current = ScrollSmoother.create({
				smooth: 2, // seconds it takes to "catch up" to native scroll position
				effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
			});
		}, main);

		return () => ctx.revert();
	}, []);

	return (
		<div id='smooth-wrapper' ref={main}>
			<div id='smooth-content'>{children}</div>
		</div>
	);
};

// Exports
// ------
export default SmoothScroll;
