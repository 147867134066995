// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { emStyles } from '@tackl/type'

// Exports
// ------------
export const Jacket = styled.form(props => css`
    position: relative;
    width: 100%;
    
    ${props.isFooter && css`
        display: flex;
        flex-flow: column;
        gap: 1.2rem;
    `}
`);


export const Fields = styled.div(props => css`
    display: flex;
    gap: 0.6rem;
    width: 100%;

    ${props.isFooter && css`
        flex-flow: column;
        gap: 0.6rem;
    `}

    input[type=email] {
        ${emStyles}
        width: 100%;
        border-radius: 2.1rem;
        padding: 0.4rem 1.2rem 0.2rem;

        color: ${props.theme.colors.brand.bc1};
        border: 1px solid ${props.theme.colors.brand.bc1};
        background: transparent;

        &::placeholder {
            color: ${props.theme.colors.brand.bc125};
            transition: ${props.theme.easing.ease};
        }

        &:autofill {
            border: 1px solid ${props.theme.colors.brand.bc1};
            -webkit-text-fill-color: ${props.theme.colors.brand.bc1};
            -webkit-box-shadow: 0 0 0px 1000px ${props.theme.colors.brand.bc2} inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        ${breakup.large`
            &:hover {
                &::placeholder {
                    color: ${props.theme.colors.brand.bc180};
                }
            }
        `}
    }

    button {
        ${emStyles}
        padding: 0.4rem 1.2rem 0.2rem;
        width: fit-content;
        border-radius: 2.1rem;
        border: 1px solid ${props.theme.colors.brand.bc1};
        background: transparent;
        color: ${props.theme.colors.brand.bc1};
        transition: ${props.theme.easing.ease};

        &:hover {
            ${breakup.large`
                background: ${props.theme.colors.brand.bc1};
                color: ${props.theme.colors.brand.bc2};
            `}
        }

        &:active {
            background: ${props.theme.colors.brand.bc1d};
        }

        &:disabled {
            background: ${props.theme.colors.brand.bc1};
            color: ${props.theme.colors.brand.bc2};
            opacity: 0.5;
        }
    }
`);

export const Feedback = styled.label(props => css`
    position: absolute;
    top: calc(100% + 0.6rem); left: 0; right: 0;
    text-align: center;

    font-family: ${props.theme.font.type.body};
    font-weight: ${props.theme.font.weight.regular};
    font-size: 1rem;
    line-height: 1.4;
    text-transform: uppercase;

    color: ${props.theme.colors.brand.bc150};

    ${props.isError && css`
        color: ${props.theme.colors.feedback.negative};
    `}

    ${props.isPositive && css`
        color: ${props.theme.colors.brand.bc1};
    `}

    ${props.isFooter && css`
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        text-align: left;
    `}
`);