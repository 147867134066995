// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------------
const m = '4.8rem';
const d = '6rem';

export const Jacket = styled.svg(props => css`
    display: block;
    fill: ${props.theme.colors.brand.bc1};

    width: ${m};
    height: ${m};

    ${breakup.large`
        width: ${d};
        height: ${d};
    `}
`);
