// Imports
// ------------
import React, { useState, useEffect, useRef } from 'react';

// Styles
// ------------
import { Jacket, Feedback, Fields } from './styles';

// Component
// ------------
const CustomForm = ({ headerClosed, isFooter, status, message, onValidated }) => {
    // NOTE • States
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [disabled, setDisabled] = useState(false);

    // NOTE • Refs
    const form = useRef();


    // NOTE • Email Validator
    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        const test = re.test(email);

        setEmailValid(test);
    }

    const clearFields = () => {
        setEmail('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        email &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email
            });


    }

    useEffect(() => {
        if (status === "success") {
            setDisabled(true);
        }
    }, [status]);

    // NOTE • On Type: Validate email
    useEffect(() => {
        if (email !== "") {
            validateEmail(email);
        }
    }, [email]);

    useEffect(() => {
        setEmailValid(true);
    }, [headerClosed]);


    return (
        <Jacket onSubmit={(e) => handleSubmit(e)} isFooter={isFooter} ref={form}>
            <Fields isFooter={isFooter}>
                <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    placeholder="Email Address"
                    className="hover-trigger"
                    required
                    disabled={disabled}
                />

                <button
                    type="submit"
                    className="hover-trigger"
                    onClick={(e) => handleSubmit(e)}
                    disabled={disabled}
                >Submit</button>
            </Fields>

            {status === "sending" && <Feedback isFooter={isFooter}>sending...</Feedback>}

            {status === "error" && (
                <Feedback
                    isFooter={isFooter}
                    isError
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <Feedback
                    isFooter={isFooter}
                    isPositive
                    dangerouslySetInnerHTML={{ __html: message }} />
            )}

            {!emailValid && !headerClosed && status !== 'success' && (
                <>
                    {email.length >= 1 && (
                        <Feedback
                            isFooter={isFooter}
                            isError
                            dangerouslySetInnerHTML={{ __html: 'This email is not valid' }}
                        />
                    )}
                </>
            )}
        </Jacket>
    );
};

export default CustomForm;