// Imports
// ------
import styled, { css } from 'styled-components';

// Exports
// ------
export const Jacket = styled.div(
	(props) => css`
		width: 3rem;
		height: 3rem;
		position: fixed;
		z-index: 102;
		top: 0;
		left: 0;
		pointer-events: none;

		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 50%; left: 50%;
			border-radius: 50%;
			transition: ${props.theme.easing.ease};
		}

		&:before {
			width: 1.2rem;
			height: 1.2rem;

			background: ${props.theme.colors.brand.bc2};
			transform: translate(-50%, -50%) scale(${props.isHovered ? 0 : 1});
			opacity: ${props.isHovered ? 0 : 1};
		}

		&:after {
			width: 3rem;
			height: 3rem;

			border: 2px solid ${props.theme.colors.brand.bc2};
			transform: translate(-50%, -50%) scale(${props.isHovered ? 1 : 0.3});
			opacity: ${props.isHovered ? 1 : 0};
		}
	`
);
